import { intranet_config } from '../../utils/Config';

export const APP_ROUTES = {
  // Home
  Home: 'home',

  // Usuário
  USER_Home: 'usuario',

  // Ajuda
  USER_Help: 'help',



  // ----- Módulo Corporativo -----

  // Painel Gerencial
  PG_Home: intranet_config?.Home?.CORPORATIVO?.pge?.ref,


  // PFF
  PFF_Lancamento: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/lancamento`,
  PFF_Orcado_Original: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/lancamento/orcado/original`,
  PFF_Orcado_Planejado: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/lancamento/orcado/planejado`,
  PFF_Lancamento_Realizado: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/lancamento/realizado`,
  PFF_Realizado_AlteracaoMes: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/lancamento/realizado/alteracaomes`,
  PFF_Realizado_Automatico: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/lancamento/realizado/automatico`,
  PFF_Previsto: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/previsto`,
  PFF_Consulta: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/resumo`,
  PFF_Consulta_Detalhes: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/consulta/detalhes`,
  PFF_Aprovacao: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/aprovacao`,
  PFF_Aprovacao_Detalhes: `${intranet_config?.Home?.CORPORATIVO?.pff?.ref}/aprovacao/detalhes`,


  // Relatorios MKS
  RELMKS_Home: `${intranet_config?.Home?.CORPORATIVO?.rel?.ref}`,


  // Consulta de horas
  HORAS_Home: `${intranet_config?.Home?.RH?.chr?.ref}`,



  // ----- Módulo Controladoria -----

  // Custo Gerencial
  GERC_Home: `${intranet_config?.Home?.CONTROLADORIA?.cus?.ref}`,


  // Central de Custos
  CENC_Home: `${intranet_config?.Home?.CONTROLADORIA?.ccs?.ref}`,



  // ----- Módulo Recursos Humanos -----

  // RP
  RP_Home: `${intranet_config?.Home?.RH?.rpe?.ref}`,
  RP_Inclusao: `${intranet_config?.Home?.RH?.rpe?.ref}/inclusao`,
  RP_Aprovacao: `${intranet_config?.Home?.RH?.rpe?.ref}/aprovacao`,
  RP_Atendimento: `${intranet_config?.Home?.RH?.rpe?.ref}/atendimento`,
  RP_Triagem: `${intranet_config?.Home?.RH?.rpe?.ref}/triagem`,
  RP_Impressao: `${intranet_config?.Home?.RH?.rpe?.ref}/impressao`,


  // Funcionarios
  FUNC_Home: `${intranet_config?.Home?.RH?.efe?.ref}`,


  // PAV
  PAV_Destaques: `${intranet_config?.Home?.RH?.pav?.ref}/destaques`,
  PAV_Reconhecimento: `${intranet_config?.Home?.RH?.pav?.ref}/reconhecimento`,
  PAV_Configuracoes: `${intranet_config?.Home?.RH?.pav?.ref}/config`,



  // ----- Módulo SGI -----

  // PIC
  PIC_Home: `${intranet_config?.Home?.SGI?.pic?.ref}`,
  PIC_Cadastro_Pic: `${intranet_config?.Home?.SGI?.pic?.ref}/cadastro`,
  PIC_Cadastro_Equipe: `${intranet_config?.Home?.SGI?.pic?.ref}/cadastro/equipe`,
  PIC_Cadastro_Area: `${intranet_config?.Home?.SGI?.pic?.ref}/cadastro/area`,
  PIC_Consulta: `${intranet_config?.Home?.SGI?.pic?.ref}/consulta`,
  PIC_Consulta_Impressao: `${intranet_config?.Home?.SGI?.pic?.ref}/consulta/impressao`,


  // POC
  POC_Home: `${intranet_config?.Home?.SGI?.poc?.ref}`,
  POC_Cadastro: `${intranet_config?.Home?.SGI?.poc?.ref}/cadastro`,
  POC_Consulta: `${intranet_config?.Home?.SGI?.poc?.ref}/consulta`,
  POC_Consulta_Impressao: `${intranet_config?.Home?.SGI?.poc?.ref}/consulta/impressao`,



  // ----- Módulo Tecnologia da Informaçao -----

  // Gestão de Identidade
  GI_Administracao: `${intranet_config?.Home?.TI?.gid?.ref}/administracao`,
  GI_Consulta: `${intranet_config?.Home?.TI?.gid?.ref}/consulta`,



  // ----- Módulo Suprimentos -----

  // Compras / Contratos
  PBC_Aprovacao: `${intranet_config?.Home?.SUP?.pcb?.ref}`,
  PBC_Pedidos: `${intranet_config?.Home?.SUP?.pcb?.ref}/consulta`,
  PBC_Contratos: `${intranet_config?.Home?.SUP?.pcb?.ref}/contratos`,
  PBC_Anexo: `${intranet_config?.Home?.SUP?.pcb?.ref}/anexo`,
  PBC_Manutencao: `${intranet_config?.Home?.SUP?.pcb?.ref}/manutencao`,
  PBC_ContratosItensImpressao: `${intranet_config?.Home?.SUP?.pcb?.ref}/contratos/itens`,
  PBC_ImpressaoBM: `${intranet_config?.Home?.SUP?.pcb?.ref}/impressao/bm`,
  PBC_ImpressaoPC: `${intranet_config?.Home?.SUP?.pcb?.ref}/impressao/pc`,


  // Suprimentos
  SME_Home: `${intranet_config?.Home?.SUP?.sme?.ref}`,
  SME_Solicitacao: `${intranet_config?.Home?.SUP?.sme?.ref}/solicitacao`,
  SME_Nova: `${intranet_config?.Home?.SUP?.sme?.ref}/nova_sm/nova`,
  SME_Aprovacao: `${intranet_config?.Home?.SUP?.sme?.ref}/aprovacao`,
  SME_Triagem: `${intranet_config?.Home?.SUP?.sme?.ref}/triagem`,
  SME_Atendimento: `${intranet_config?.Home?.SUP?.sme?.ref}/atendimento`,
  SME_Monitor: `${intranet_config?.Home?.SUP?.sme?.ref}/monitor`,
  SME_ManutencaoTriagem: `${intranet_config?.Home?.SUP?.sme?.ref}/manutencao`,
  SME_Impressao: `${intranet_config?.Home?.SUP?.sme?.ref}/impressao`,


  // Fornecedores
  FORNECEDORES_Home: `${intranet_config?.Home?.SUP?.for?.ref}`,


  // PStock
  PSTOCK_HomeScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}`,
  PSTOCK_AdministracaoScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/administracao`,
  PSTOCK_ParametrizacaoScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/criacao`,
  PSTOCK_MonitorScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/monitor`,
  NovoFuncionarioScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/funcionario/novo`,
  NovoFornecedorScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/fornecedor/novo`,
  NovoProdutoScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/produto/novo`,
  PSTOCK_RelatoriosScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/relatorios`,
  PSTOCK_EntradaScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/entrada`,
  PSTOCK_SaidaScreen: `${intranet_config?.Home?.PSTOK?.adm?.ref}/saida`,

};



const NameRoutes = {
  ...APP_ROUTES,
};



export default NameRoutes;
