import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router';

import {
  Colors,
  Metrics,
} from '../../common/constants';

import {
  Content,
  Overlay,
  Screen,
} from '../../common/styles/styled.layout';

import CardDashboard from '../../components/Cards/CardDashboard';
import CardHome from '../../components/Cards/CardHome';
import NavHeader from '../../components/Headers/NavHeader';
import NavUser from '../../components/Headers/NavUser';

import {
  IconCardId,
  IconCart,
  // IconChartBar, // Desabilitado
  // IconCheckCopy, // Desabilitado
  IconContract,
  // IconCookie,
  IconDocuments, // Desabilitado
  IconEyeOpen,
  IconHelpOutline,
  IconList,
  IconListFeatured,
  IconMoney,
  IconMonitor,
  IconOrganization,
  IconPresentation,
  IconTruck,
  IconTwilight,
  IconUser,
  IconUserCheck,
  IconUserPlus,
  IconMailWeb,
  IconUsers,
  IconArrowRight,
  IconStock,
  IconHardHat,
  IconObjectGroup,
  IconArchive,
  IconPeopleBetween,
  IconHour,
  IconCostPFX,
} from '../../components/Icons';

import NameRoutes from '../../navigation/name';

import {
  RootState,
} from '../../store';
import { intranet_config } from '../../utils/Config';

import {
  HomeLogoContent,
  LogoContainer,
  ImageViewLogo,
  ImageLogo,
  UserViewContent,
  UserViewButton,
  UserTextButton,
  // DashboardAutoView,
  DashboardCardsContainer,
} from './styled';



const HomeScreen: React.FC = () => {
  const history = useHistory();


  const user = useSelector((state: RootState) => state.user.data);



  return (

    <Screen>

      <NavHeader>
        <NavUser />
      </NavHeader>



      <Content>

        <HomeLogoContent>

          <Overlay />


          <LogoContainer>

            <ImageViewLogo>
              <ImageLogo
                alt={`${intranet_config?.Registration?.name} Logo`}
                src={intranet_config?.Images?.logo}
              />
            </ImageViewLogo>


            <UserViewContent>

              <UserViewButton
                onClick={() => {
                  history?.push(`/${NameRoutes?.USER_Home}`);
                }}>
                <IconUser
                  color={'#000000'}
                  size={Metrics?.iconHomeSize - 20}
                />

                <UserTextButton>
                  {user?.login}
                </UserTextButton>

                <IconArrowRight
                  color={'#000000'}
                  size={Metrics?.iconHomeSize - 30}
                />
              </UserViewButton>

            </UserViewContent>

          </LogoContainer>

        </HomeLogoContent>



        {/* <DashboardAutoView> */}
        <DashboardCardsContainer>
          {intranet_config?.Home?.CORPORATIVO?.enabled === '63d1dt5sko0er' && (
            <CardDashboard
              title={'Corporativo'}>

              {intranet_config?.Home?.CORPORATIVO?.pge?.enabled === '63d1d1d0cf34b' && (
                <CardHome
                  icon={
                    <IconEyeOpen
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Painel Gerencial'}
                  description={'Painéis com informações gerenciais para acompanhamento da operações'}
                  onClick={() => {
                    if (intranet_config?.Home?.CORPORATIVO?.pge?.link) {
                      window?.open(intranet_config?.Home?.CORPORATIVO?.pge?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.PG_Home}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.CORPORATIVO?.pex?.enabled === '63d1d6a4d9bb8' && (
                <CardHome
                  icon={
                    <IconCostPFX
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'PEX'}
                  description={'Importação, Consulta e Aprovação de Custos Operacionais'}
                  onClick={() => {
                    if (intranet_config?.Home?.CORPORATIVO?.pex?.link) {
                      window?.open(intranet_config?.Home?.CORPORATIVO?.pex?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/404');
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.CORPORATIVO?.pff?.enabled === '63d1d1e90c5fe' && (
                <CardHome
                  icon={
                    <IconTwilight
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'PFF'}
                  description={'Lançamento, Consulta e Aprovação de Orçamentos, Previsões e Realizados das Operações'}
                  onClick={() => {
                    if (intranet_config?.Home?.CORPORATIVO?.pge?.link) {
                      window?.open(intranet_config?.Home?.CORPORATIVO?.pff?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.PFF_Lancamento}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.CORPORATIVO?.rel?.enabled === '63d1d64e45935' && (
                <CardHome
                  icon={
                    <IconList
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Relatórios MKS'}
                  description={'Relatórios Gerenciais de apoio Operacional'}
                  onClick={() => {
                    if (intranet_config?.Home?.CORPORATIVO?.rel?.link) {
                      window?.open(intranet_config?.Home?.CORPORATIVO?.rel?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.CORPORATIVO?.bin?.enabled === '63d1d65652e88' && (
                <CardHome
                  icon={
                    <IconPresentation
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'BI'}
                  description={'Business Intelligence'}
                  onClick={() => {
                    if (intranet_config?.Home?.CORPORATIVO?.bin?.link) {
                      window?.open(intranet_config?.Home?.CORPORATIVO?.bin?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}

          {intranet_config?.Home?.CONTROLADORIA?.enabled === '63d1d2ikd8sle' && (
            <CardDashboard
              title={'Controladoria'}
              color={Colors?.gridControladoria}>

              {intranet_config?.Home?.CONTROLADORIA?.cus?.enabled === '63d1d65b23a9b' && (
                <CardHome
                  color={Colors?.gridControladoria}
                  icon={
                    <IconMoney
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Custo Gerencial'}
                  description={'Analise dos Custos Gerenciais por Negocio ou OS no período'}
                  onClick={() => {
                    if (intranet_config?.Home?.CONTROLADORIA?.cus?.link) {
                      window?.open(intranet_config?.Home?.CONTROLADORIA?.cus?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.GERC_Home}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.CONTROLADORIA?.ccs?.enabled === '63d1d65ebaf8e' && (
                <CardHome
                  color={Colors?.gridControladoria}
                  icon={
                    <IconOrganization
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Centro de Custos'}
                  description={'Consulta dos Centro de Custos e os responsáveis'}
                  onClick={() => {
                    if (intranet_config?.Home?.CONTROLADORIA?.ccs?.link) {
                      window?.open(intranet_config?.Home?.CONTROLADORIA?.ccs?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.CENC_Home}`);
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}



          {intranet_config?.Home?.RH?.enabled === '63d1dw85d2cv4' && (
            <CardDashboard
              color={Colors?.gridRH}
              title={'Recursos Humanos'}>

              {intranet_config?.Home?.RH?.rpe?.enabled === '63d1d662ae024' && (
                <CardHome
                  color={Colors?.gridRH}
                  icon={
                    <IconUserPlus
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'RP'}
                  description={'Sistema para Emissão, Consulta e Aprovação de Requisições de Pessoal'}
                  onClick={() => {
                    if (intranet_config?.Home?.RH?.rpe?.link) {
                      window?.open(intranet_config?.Home?.RH?.rpe?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.RP_Home}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.RH?.efe?.enabled === '63d1d666d3e7a' && (
                <CardHome
                  color={Colors?.gridRH}
                  icon={
                    <IconUsers
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Efetivo'}
                  description={'Consulta a Situação do Efetivo da Empresa'}
                  onClick={() => {
                    if (intranet_config?.Home?.RH?.efe?.link) {
                      window?.open(intranet_config?.Home?.CONTROLADORIA?.ccs?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.FUNC_Home}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.RH?.mrh?.enabled === '63d1d66a94b36' && (
                <CardHome
                  color={Colors?.gridRH}
                  icon={
                    <IconUserCheck
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Meu RH'}
                  description={'O TOTVS Meu RH é uma solução corporativa que torna as rotinas de RH mais simples e eficientes?.'}
                  onClick={() => {
                    if (intranet_config?.Home?.RH?.mrh?.link) {
                      window?.open(intranet_config?.Home?.RH?.mrh?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.RH?.chr?.enabled === '63de7tt5c6e88' && (
                <CardHome
                  color={Colors?.gridRH}
                  icon={
                    <IconHour
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Grade de Horas'}
                  description={'Painel de consulta de horas sintéticas, analíticas e completas?.'}
                  onClick={() => {
                    if (intranet_config?.Home?.RH?.chr?.link) {
                      window?.open(intranet_config?.Home?.RH?.chr?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.HORAS_Home}`);
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}


          {intranet_config?.Home?.SGI?.enabled === '63d1dr85s12ps' && (
            <CardDashboard
              color={Colors?.gridSGI}
              title={'SGI'}>

              {intranet_config?.Home?.SGI?.nco?.enabled === '63d1d6723d6eb' && (
                <CardHome
                  color={Colors?.gridSGI}
                  icon={
                    <IconDocuments
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Não Conformidade'}
                  description={'Ocorrências, planos de ações, analises de causa, consultas a documentos e retenção de registros'}
                  onClick={() => {
                    if (intranet_config?.Home?.SGI?.nco?.link) {
                      window?.open(intranet_config?.Home?.SGI?.nco?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}

              {intranet_config?.Home?.SGI?.pqu?.enabled === '63d1d675d3726' && (
                <CardHome
                  color={Colors?.gridSGI}
                  icon={
                    <IconObjectGroup
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Portal da Qualidade'}
                  description={'RQS, EPS e RQPS'}
                  onClick={() => {
                    if (intranet_config?.Home?.SGI?.pqu?.link) {
                      window?.open(intranet_config?.Home?.SGI?.pqu?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.SGI?.pic?.enabled === '63d1d67987c52' && (
                <CardHome
                  color={Colors?.gridSGI}
                  icon={
                    <IconHardHat
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'PIC'}
                  description={'Sistema para cadastro de ficha do programa de inspeção de comportamento'}
                  onClick={() => {
                    if (intranet_config?.Home?.SGI?.pic?.link) {
                      window?.open(intranet_config?.Home?.SGI?.pic?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.PIC_Consulta}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.SGI?.poc?.enabled === '63d1d66354r22' && (
                <CardHome
                  color={Colors?.gridSGI}
                  icon={
                    <IconHardHat
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'POC'}
                  description={'Sistema para cadastro de ficha do programa de observação comportamental'}
                  onClick={() => {
                    if (intranet_config?.Home?.SGI?.poc?.link) {
                      window?.open(intranet_config?.Home?.SGI?.poc?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.POC_Consulta}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.SGI?.ged?.enabled === '63d1d680675a9' && (
                <CardHome
                  color={Colors?.gridSGI}
                  icon={
                    <IconListFeatured
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'GED'}
                  description={'Consulta e Acesso ao Documentos  Normativos da empresa'}
                  onClick={() => {
                    if (intranet_config?.Home?.SGI?.ged?.link) {
                      window?.open(intranet_config?.Home?.SGI?.ged?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}


          {intranet_config?.Home?.TI?.enabled === '63d1d554sseu8' && (
            <CardDashboard
              color={Colors?.gridTI}
              title={'Tecnologia da Informação'}>

              {intranet_config?.Home?.TI?.gid?.enabled === '63d1d68547d6d' && (
                <CardHome
                  color={Colors?.gridTI}
                  icon={
                    <IconCardId
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Gestão de Identidade'}
                  description={'Gestão de Identidade eletrônica que gerencia os permissionamentos dos usuários'}
                  onClick={() => {
                    if (intranet_config?.Home?.TI?.gid?.link) {
                      window?.open(intranet_config?.Home?.TI?.gid?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.GI_Administracao}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.TI?.swe?.enabled === '63d1d689b8c7f' && (
                <CardHome
                  color={Colors?.gridTI}
                  icon={
                    <IconMonitor
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Siga WEB'}
                  description={'Acesso ao smartclient do Protheus via WEB'}
                  onClick={() => {
                    if (intranet_config?.Home?.TI?.swe?.link) {
                      window?.open(intranet_config?.Home?.TI?.swe?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.TI?.hpr?.enabled === '63d1d68ced7ea' && (
                <CardHome
                  color={Colors?.gridTI}
                  icon={
                    <IconHelpOutline
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Help Protheus'}
                  description={'Sistema de ajuda ao Protheus'}
                  onClick={() => {
                    if (intranet_config?.Home?.TI?.hpr?.link) {
                      window?.open(intranet_config?.Home?.TI?.hpr?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.TI?.wma?.enabled === '63d1d690b8b68' && (
                <CardHome
                  color={Colors?.gridTI}
                  icon={
                    <IconMailWeb
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'WEB Mail'}
                  description={'Acesso a WEB Email'}
                  onClick={() => {
                    if (intranet_config?.Home?.TI?.wma?.link) {
                      window?.open(intranet_config?.Home?.TI?.wma?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}


          {intranet_config?.Home?.SUP?.enabled === '63d1d554ss47e' && (
            <CardDashboard
              color={Colors?.gridSUP}
              title={'Suprimentos'}>

              {intranet_config?.Home?.SUP?.pcb?.enabled === '63d1d69415e36' && (
                <CardHome
                  color={Colors?.gridSUP}
                  icon={
                    <IconContract
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Compras / Contratos'}
                  description={'Sistema para consulta, manutenção e aprovação de Pedidos, BM e Contratos'}
                  onClick={() => {
                    if (intranet_config?.Home?.SUP?.pcb?.link) {
                      window?.open(intranet_config?.Home?.SUP?.pcb?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.PBC_Aprovacao}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.SUP?.sme?.enabled === '63d1d697a2e55' && (
                <CardHome
                  color={Colors?.gridSUP}
                  icon={
                    <IconCart
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'SME'}
                  description={'Sistema para Emissão, Consulta, Triagem e Aprovação de Solicitações de Material'}
                  onClick={() => {
                    if (intranet_config?.Home?.SUP?.sme?.link) {
                      window?.open(intranet_config?.Home?.SUP?.sme?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.SME_Home}`);
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.SUP?.for?.enabled === '63d1d69c7f3a0' && (
                <CardHome
                  color={Colors?.gridSUP}
                  icon={
                    <IconTruck
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Fornecedores'}
                  description={'Consulta de Fornecedores'}
                  onClick={() => {
                    if (intranet_config?.Home?.SUP?.for?.link) {
                      window?.open(intranet_config?.Home?.SUP?.for?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.FORNECEDORES_Home}`);
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}


          {intranet_config?.Home?.PSTOK?.enabled === '63d1d55t8r9e1' && (
            <CardDashboard
              color={Colors?.gridPSTOK}
              title={'PStock'}>


              {intranet_config?.Home?.PSTOK?.adm?.enabled === '63d1d6a019fbc' && (
                <CardHome
                  color={Colors?.gridPSTOK}
                  icon={
                    <IconStock
                      color={'#000000'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Administração'}
                  description={'Módulo de configurações'}
                  onClick={() => {
                    if (intranet_config?.Home?.PSTOK?.adm?.link) {
                      window?.open(intranet_config?.Home?.PSTOK?.adm?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push(`/${NameRoutes?.PSTOCK_HomeScreen}`);
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}


          {intranet_config?.Home?.LEGADOS?.enabled === '63d1d554dde47' && (
            <CardDashboard
              color={Colors?.gridLegados}
              title={'Legados'}>


              {intranet_config?.Home?.LEGADOS?.pff?.enabled === '63d1d6a4d9bb8' && (
                <CardHome
                  color={Colors?.gridLegados}
                  icon={
                    <IconArchive
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'PFF'}
                  description={'Lançamento, Consulta e Aprovação de Orçamentos, Previsões e Realizados das Operações'}
                  onClick={() => {
                    if (intranet_config?.Home?.LEGADOS?.pff?.link) {
                      window?.open(intranet_config?.Home?.LEGADOS?.pff?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}



              {intranet_config?.Home?.LEGADOS?.pge?.enabled === '63d1d6a83c28b' && (
                <CardHome
                  color={Colors?.gridLegados}
                  icon={
                    <IconArchive
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Painel Gerencial'}
                  description={'Painéis com informações gerenciais para acompanhamento da operações'}
                  onClick={() => {
                    if (intranet_config?.Home?.LEGADOS?.pge?.link) {
                      window?.open(intranet_config?.Home?.LEGADOS?.pge?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}


              {intranet_config?.Home?.LEGADOS?.gid?.enabled === '63d1d6ac50253' && (
                <CardHome
                  color={Colors?.gridLegados}
                  icon={
                    <IconArchive
                      color={'black'}
                      size={Metrics?.iconHomeSize}
                    />
                  }
                  title={'Gestão de Identidade'}
                  description={'Gestão de Identidade eletrônica que gerencia os permissionamentos dos usuários'}
                  onClick={() => {
                    if (intranet_config?.Home?.LEGADOS?.gid?.link) {
                      window?.open(intranet_config?.Home?.LEGADOS?.gid?.link, '_blank')?.focus();
                    }
                    else {
                      history?.push('/');
                    }
                  }}
                />
              )}

            </CardDashboard>
          )}

        </DashboardCardsContainer>
        {/* </DashboardAutoView> */}

      </Content>

    </Screen>

  );
};



export default HomeScreen;
